define("discourse/plugins/discourse-ai/discourse/connectors/user-preferences-nav/ai-preferences", ["exports", "@glimmer/component", "@ember/routing", "discourse-common/helpers/d-icon", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _routing, _dIcon, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function showAiPreferences(user1) {
    // Since we only have one AI related user setting we don't show
    // AI preferences if these conditions aren't met.
    // If we add more user settings in the future we can move this
    // logic to the the specific settings and conditionally show it in the template.
    return user1?.user_allowed_ai_auto_image_captions;
  }
  class AutoImageCaptionSetting extends _component.default {
    static shouldRender(outletArgs1, helper1) {
      return helper1.siteSettings.discourse_ai_enabled && showAiPreferences(outletArgs1.model);
    }
    static #_ = (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <li class="user-nav__preferences-ai">
          <LinkTo @route="preferences.ai">
            {{dIcon "discourse-sparkles"}}
            <span>{{i18n "discourse_ai.title"}}</span>
          </LinkTo>
        </li>
      
    */
    {
      "id": "/y5E1bgJ",
      "block": "[[[1,\"\\n    \"],[10,\"li\"],[14,0,\"user-nav__preferences-ai\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@route\"],[\"preferences.ai\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[32,1],[\"discourse-sparkles\"],null]],[1,\"\\n        \"],[10,1],[12],[1,[28,[32,2],[\"discourse_ai.title\"],null]],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/user-preferences-nav/ai-preferences.js",
      "scope": () => [_routing.LinkTo, _dIcon.default, _i18n.default],
      "isStrictMode": true
    }), this);
  }
  _exports.default = AutoImageCaptionSetting;
});