define("discourse/plugins/discourse-ai/discourse/services/image-caption-popup", ["exports", "@glimmer/tracking", "@ember/service", "discourse/plugins/discourse-ai/discourse/lib/utilities"], function (_exports, _tracking, _service, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ImageCaptionPopup extends _service.default {
    static #_ = dt7948.g(this.prototype, "composer", [_service.inject]);
    #composer = (dt7948.i(this, "composer"), void 0);
    static #_2 = dt7948.g(this.prototype, "appEvents", [_service.inject]);
    #appEvents = (dt7948.i(this, "appEvents"), void 0);
    static #_3 = dt7948.g(this.prototype, "showPopup", [_tracking.tracked], function () {
      return false;
    });
    #showPopup = (dt7948.i(this, "showPopup"), void 0);
    static #_4 = dt7948.g(this.prototype, "imageIndex", [_tracking.tracked], function () {
      return null;
    });
    #imageIndex = (dt7948.i(this, "imageIndex"), void 0);
    static #_5 = dt7948.g(this.prototype, "imageSrc", [_tracking.tracked], function () {
      return null;
    });
    #imageSrc = (dt7948.i(this, "imageSrc"), void 0);
    static #_6 = dt7948.g(this.prototype, "newCaption", [_tracking.tracked], function () {
      return null;
    });
    #newCaption = (dt7948.i(this, "newCaption"), void 0);
    static #_7 = dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    });
    #loading = (dt7948.i(this, "loading"), void 0);
    static #_8 = dt7948.g(this.prototype, "popupTrigger", [_tracking.tracked], function () {
      return null;
    });
    #popupTrigger = (dt7948.i(this, "popupTrigger"), void 0);
    static #_9 = dt7948.g(this.prototype, "showAutoCaptionLoader", [_tracking.tracked], function () {
      return false;
    });
    #showAutoCaptionLoader = (dt7948.i(this, "showAutoCaptionLoader"), void 0);
    static #_10 = dt7948.g(this.prototype, "_request", [_tracking.tracked], function () {
      return null;
    });
    #_request = (dt7948.i(this, "_request"), void 0);
    updateCaption() {
      const matchingPlaceholder = this.composer.model.reply.match(_utilities.IMAGE_MARKDOWN_REGEX);
      if (matchingPlaceholder) {
        const match = matchingPlaceholder[this.imageIndex];
        const replacement = match.replace(_utilities.IMAGE_MARKDOWN_REGEX, `![${this.newCaption}|$2$3$4]($5)`);
        if (match) {
          this.appEvents.trigger("composer:replace-text", match, replacement);
        }
      }
    }
    toggleLoadingState(loading) {
      if (loading) {
        this.popupTrigger?.classList.add("disabled");
        return this.loading = true;
      }
      this.popupTrigger?.classList.remove("disabled");
      return this.loading = false;
    }
  }
  _exports.default = ImageCaptionPopup;
});